<script lang="ts" setup>
const props = defineProps(['to']);
const localePath = useLocalePath();
function asdf() {
  navigateTo(localePath(props.to));
}
</script>

<template>
  <div>
    <div @click="asdf()"
      class="grid dark:hover:bg-blue-200 hover:bg-blue-700 text-blue-800 dark:text-blue-200 hover:text-blue-50 dark:hover:text-blue-800 dark:hover:stroke-blue-600 font-bold items-center leftmenuexpandedwidth">
      <div class="maxicon ">
        <slot name="icon">
          icon
        </slot>
      </div>
      <div class="maxtext">
        <slot name="text">text</slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.leftmenuexpandedwidth {
  grid-template-columns: 5vw 25vw;
  cursor: pointer;
}



</style>
