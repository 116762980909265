<template>

  <div class="container nav-container ">
    <div class="topstrip dark:bg-blue-500 bg-blue-100  ">


    </div>
    <input type="checkbox" v-model="check" class="hamburger checkbox " id="menu-toggle" name="" />
    <Langswitcher class="langsven" />
    <div class="logo ">

      <NuxtLink :to="localePath('/')">Jonas Jakobsson</NuxtLink>
    </div>
    <div class="hamburger hamburger-lines ">
      <span class="line line1 bg-blue-700 dark:bg-blue-50"></span>
      <span class="line line2 bg-blue-700 dark:bg-blue-50"></span>
      <span class="line line3 bg-blue-700 dark:bg-blue-50"></span>
    </div>
    <div class="menu bg-blue-100 dark:bg-blue-500 top-0 left-0 fill-blue-300 dark:fill-blue-900 text-blue-200 dark:text-blue-50
    stroke-blue-900 dark:stroke-blue-200 ">
      <Leftmenu />
    </div>
    <div class="main ">

      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
useHead({
  bodyAttrs: {
    class: 'bg-blue-200 dark:bg-blue-700'
  }
})

const check = ref(false);
const localePath = useLocalePath();
const nuxtApp = useNuxtApp();

const loading = ref(false);
nuxtApp.hook("page:start", () => {
  check.value = false;
});
</script>

<style>
.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}

.layout-enter-from,
.layout-leave-to {
  filter: grayscale(1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.topstrip {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 64px;
  z-index: 5;
  align-items: center;
}

.logo {
  position: absolute;
  top: 1.5vh;
  z-index: 9;
  right: 20px;
  text-transform: uppercase;
  font-size: larger;
  font-weight: bolder;

}

.langsven {
  position: absolute;
  top: 17px;
  z-index: 11;
  right: 16em;
  height: 24px;
  width: 24px;

}

.pair {
  position: absolute;
  left: 0px;
  top: 0px;
  min-height: 100%;
  padding-bottom: 32px;

}

.clip-diagonal {
  clip-path: polygon(0 0, 0% 100%, 100% 0);
}

.clip-diagonal-inverted {
  clip-path: polygon(0 100%, 100% 0, 100% 100%);
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-height: 100dvh;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;
}


.container {
  width: 100vw;
  max-width: 100%;
  display: block;
  transition: 500ms;

}

.menu {
  position: relative;
  min-height: calc(100vh - 64px);
  min-height: calc(100dvh - 64px);
  top: 64px;
  padding: 0px;
}

.main {
  position: relative;

  height: calc(100vh - 64px);
  height: calc(100dvh - 64px);
  width: 100%;
  overflow-x: none;
  overflow-y: auto;
  word-wrap: break-word;

}


@media (hover: hover) and (min-width: 640px) {

  .hamburger {
    display: none;
  }

  .container:has(.menu:hover) {
    grid-template-columns: 30vw auto;
  }

  .container {
    display: grid;
    grid-template-columns: 5vw auto;

  }

  .menu {
    position: relative;


  }

  .main {
    position: relative;
    width: 100%;
    top: 64px;
    left: 0px;

  }

}

@media (hover: none) or (max-width: 640px) {
  .hamburger {
    display: block;
    position: absolute;
    z-index: 6;
    left: 10vw;
    width: 5vw;
    height: 6vh;
  }

  .container {
    display: block;
  }

  .menu {
    width: 30vw;
    float: left;
    z-index: 3;

  }

  .main {
    position: absolute;
    z-index: 2;
    top: 64px;
    right: 0px;
    width: 100%;
    transition: width 0.4s ease-in-out 0.1s;
    transform-style: flat;
  }

  /* .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
  } */

  /* .navbar .menu-items {
    display: flex;
  } */

  /* .navbar .nav-container li {
    list-style: none;
  } */

  /* .navbar .nav-container a {
    text-decoration: none;
    color: #0e2431;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0.7rem;
  } */
  /* 
  .navbar .nav-container a:hover {
    font-weight: bolder;
  } */
  /* 
  .nav-container {
    display: block;
    position: relative;
    height: 60px;
  } */


  .nav-container .checkbox {
    position: absolute;
    display: block;
    height: 6vh;
    width: 5vh;
    top: 1vh;
    left: 2vh;
    z-index: 10;
    opacity: 0;
    cursor: pointer;
  }

  .nav-container .hamburger-lines {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 17px;
    left: 20px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
  }

  .nav-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .nav-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  .container .menu {
    padding-top: 7vh;
    width: 60%;
    transform: translate(-100%);
    display: flex;
    flex-direction: column;
    margin-left: -40px;
    padding-left: 50px;
    transition: transform 0.5s ease-in-out;
    text-align: center;
  }

  /* .navbar .menu-items li {
    margin-bottom: 1.2rem;
    font-size: 1.5rem;
    font-weight: 500;
  } */

  .nav-container input[type="checkbox"]:checked~.menu {
    transform: translateX(0);
  }

  .nav-container input[type="checkbox"]:checked~.main {
    width: 80%;
  }

  .nav-container input[type="checkbox"]:checked~.hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .nav-container input[type="checkbox"]:checked~.hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .nav-container input[type="checkbox"]:checked~.hamburger-lines .line3 {
    transform: rotate(-45deg);
  }

  .nav-container input[type="checkbox"]:checked~.logo {
    display: none;
  }

}

/* check if browser supports css :has */
@supports (not (selector(:has(p)))) {
  .hamburger {
    display: block;
    position: absolute;
    z-index: 6;
    left: 10vw;
    width: 5vw;
    height: 6vh;
  }

  .container {
    display: block;
  }

  .menu {
    width: 30vw;
    float: left;
    z-index: 3;

  }

  .main {
    position: absolute;
    z-index: 2;
    top: 64px;
    right: 0px;
    width: 100%;
    transition: width 0.4s ease-in-out 0.1s;
    transform-style: flat;
  }

  /* .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
  } */

  /* .navbar .menu-items {
    display: flex;
  } */

  /* .navbar .nav-container li {
    list-style: none;
  } */

  /* .navbar .nav-container a {
    text-decoration: none;
    color: #0e2431;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0.7rem;
  } */
  /* 
  .navbar .nav-container a:hover {
    font-weight: bolder;
  } */
  /* 
  .nav-container {
    display: block;
    position: relative;
    height: 60px;
  } */


  .nav-container .checkbox {
    position: absolute;
    display: block;
    height: 6vh;
    width: 5vh;
    top: 1vh;
    left: 2vh;
    z-index: 10;
    opacity: 0;
    cursor: pointer;
  }

  .nav-container .hamburger-lines {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 17px;
    left: 20px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
  }

  .nav-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .nav-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  .container .menu {
    padding-top: 7vh;
    width: 60%;
    transform: translate(-100%);
    display: flex;
    flex-direction: column;
    margin-left: -40px;
    padding-left: 50px;
    transition: transform 0.5s ease-in-out;
    text-align: center;
  }

  /* .navbar .menu-items li {
    margin-bottom: 1.2rem;
    font-size: 1.5rem;
    font-weight: 500;
  } */

  .nav-container input[type="checkbox"]:checked~.menu {
    transform: translateX(0);
  }

  .nav-container input[type="checkbox"]:checked~.main {
    width: 80%;
  }

  .nav-container input[type="checkbox"]:checked~.hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .nav-container input[type="checkbox"]:checked~.hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .nav-container input[type="checkbox"]:checked~.hamburger-lines .line3 {
    transform: rotate(-45deg);
  }

  .nav-container input[type="checkbox"]:checked~.logo {
    display: none;
  }


}
</style>

